import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

import { GridContainer as GridContainerBase } from '@/components/Grid'
import { Container as InputSelect, DropDownItem, Placeholder as InputSelectPlaceholder, Wrapper as InputSelectWrapper, Label as InputSelectLabel } from '@/components/Forms/Inputs/Select/style'

import { Container as InputCheckbox, Input as InputCheckboxInput, Label as InputCheckboxLabel } from '@/components/Forms/Inputs/Checkbox/style'

import { Container as InputText, Wrapper as InputTextWrapper, InputWrapper as InputTextInputWrapper, Input as InputTextInput, Label as InputTextLabel } from '@/components/Forms/Inputs/Text/style'
import { Container as InputTextArea, Wrapper as InputTextAreaWrapper, InputWrapper as InputTextAreaInputWrapper, Input as InputTextAreaInput, Label as InputTextAreaLabel } from '@/components/Forms/Inputs/TextArea/style'
import { Container as InputEmail, Wrapper as InputEmailWrapper, InputWrapper as InputEmailInputWrapper, Input as InputEmailInput, Label as InputEmailLabel } from '@/components/Forms/Inputs/Email/style'

import { ButtonWrapper as Button } from '@/components/Button/style'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10rem;

  ${mediaMax.sm} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const GridContainer = styled(GridContainerBase)``

export const Head = styled.div`
  margin-top: 7rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Subtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${p => p.theme.colors.lightGreen2};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: 8rem;
  margin-bottom: 3.5rem;

  ${mediaMax.sm} {
    font-size: 1.2rem;
  }
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 5.2rem;
  color: ${p => p.theme.colors.lightGreen2};
  margin-bottom: 2rem;
  // width: 14ch;
  font-weight: bold;

  ${mediaMax.sm} {
    font-size: 4rem;
  }
`

export const FormContainer = styled.form`
  --fs-placeholders: 1.2rem;
  --fs-labels: 1.4rem;
  --fs-form-group-labels: 1.2rem;

  --gap-inputs: 2rem;
  --gap-form-group: 2rem;
  --gap-form-group-label: 2rem;

  --padding-input-x: 2.5rem;
  --padding-input-y: 1.6rem;

  margin: auto;
  max-width: 80rem;
  ${mediaMax.sm}{
    --fs-placeholders: 1.4rem;
    --fs-labels: 1.2rem;
    --padding-input-x: 2rem;
    --padding-input-y: 1rem;
    ${Button}{
      padding: 1.8rem 4rem;
    }
  }

`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap-form-group);
  ${InputSelect}, ${InputText}, ${InputTextArea}, ${InputEmail}, ${InputCheckbox}{
    background-color: transparent;
  }

  ${InputTextLabel}, ${InputTextAreaLabel}, ${InputEmailLabel}, ${InputSelectLabel}{
    display: flex;
    font-size: var(--fs-labels);
  }

  ${InputCheckboxLabel}{
    white-space: normal;
    color: ${p => p.theme.colors.gray};
  }

  ${InputCheckboxInput}{
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    &::before{
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
  }


  ${InputTextInput}, ${InputTextAreaInput}, ${InputEmailInput}{
    padding: 0;
    font-weight: bold !important;
    font-family: ${p => p.theme.fonts.sansSerif} !important;
    font-size: var(--fs-placeholders) !important;
    color: ${p => p.theme.colors.black} !important;
    text-transform: none;
    text-align: left;

    &::placeholder {
      font-weight: bold !important;
      font-family: ${p => p.theme.fonts.sansSerif} !important;
      font-size: var(--fs-placeholders) !important;
      color: ${p => p.theme.colors.black} !important;
      text-transform: none;
      text-align: left;
    }
  }

  ${InputSelectPlaceholder}{
    font-weight: bold !important;
    font-family: ${p => p.theme.fonts.sansSerif} !important;
    font-size: var(--fs-placeholders) !important;
    color: ${p => p.theme.colors.black} !important;
    text-transform: none;
    text-align: left;
  }

  ${DropDownItem}{
    background-color: ${p => p.theme.colors.white};
    justify-content: center;
    padding-left: var(--padding-input-x);
  }

  ${InputSelectWrapper}{
    padding: var(--padding-input-y) var(--padding-input-x);
    justify-content: space-between;
  }

  ${InputTextInputWrapper}, ${InputTextAreaInputWrapper}, ${InputEmailInputWrapper} {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    padding: var(--padding-input-y) var(--padding-input-x);
    justify-content: flex-start;
    align-items: flex-start;

  }
  ${InputTextWrapper}, ${InputTextAreaWrapper}, ${InputEmailWrapper}, ${InputSelectWrapper} {
    &.focus, &:focus{
      border-color : ${p => p.theme.colors.black};
      outline: none;
    }
  }

`

export const FormGroupLabel = styled.span`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: var(--fs-form-group-labels);
  text-transform: uppercase;
  color: ${p => p.theme.colors.lightGreen2};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-bottom: var(--gap-form-group-label);
`

export const InputWrapper = styled.div`
  display: flex;
  gap: var(--gap-inputs);
`

export const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap-inputs);
  // margin-top: var(--gap-inputs);
  margin-top: 1rem;

  p > a {
    color: ${p => p.theme.colors.grey} !important;
    cursor: pointer;
  }
`

export const Consent = styled.div`
  font-family: ${p => p.theme.fonts.sansSerif};
  font-size: 1rem;
  color: ${p => p.theme.colors.black};
  display: flex;
  flex-direction: column;
  gap: var(--gap-inputs);

  a {
    text-decoration: underline;;
    cursor: pointer;
  }
`

export const LegalsMention = styled.p`
  font-family: ${p => p.theme.fonts.sansSerif};
  font-size: 1.2rem;
  color: ${p => p.theme.colors.brown}70;
  margin: 1rem 0;
  line-height: 2rem;
  a{
    color: ${p => p.theme.colors.brown}70;
  }
`

export const GridInputWrapper = styled(InputWrapper)`
  display: grid;
  grid-template-columns: repeat(${p => p.cols}, 1fr);
  grid-template-rows: auto;
  ${mediaMax.sm}{
    display: flex;
    flex-direction: column;
    &.identity{
      display: grid;
      grid-template-columns: .5fr 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: var(--gap-form-group);
      grid-row-gap: var(--gap-form-group);
    }
    div:nth-child(1){
      grid-area: 1 / 1 / 2 / 2;
    }
    div:nth-child(2){
      grid-area: 1 / 2 / 2 / 3;
    }
    div:nth-child(3){
      grid-area: 2 / 1 / 3 / 3;
    }
  }

`
export const Mandatory = styled.div`
    font-style: italic;
    font-family: ${p => p.theme.fonts.sansSerif};
    font-size: 1rem;
    color: ${p => p.theme.colors.green};
    line-height: 1.6rem;
    ${mediaMax.sm}{
      font-size: 1.2rem;
    }
`

export const SuccessMessage = styled.div`
  text-align: center;
  font-size: 2.5rem;
  margin-top: 4rem;
  color: ${p => p.theme.colors.green};
`

export const ErrorLabel = styled.p`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: ${(props) => props.theme.colors.redError};
  font-size: 0.8rem;
  line-height: 1.2rem;
  margin-bottom: 2rem;
  /* text-align: center; */
`
